import { SiteContext } from "@organic-return/foundation-gatsby-plugin-agility/src/components/common/SiteContext"
import { IListingSummary } from "@organic-return/foundation-react/src/lib/Listing"
import { Sort } from "@organic-return/foundation-react/src/components/Search/Sort"
import React from "react"
import { getModule } from "../components/agility-pageModules"
import {
  Check,
  Choose,
  Text,
  DateRange,
} from "@organic-return/foundation-react/src/components/Search/Filter"
import { getPageTemplate } from "../components/agility-pageTemplates"
import { IAgent } from "@organic-return/foundation-react/src/lib/Contact"
import { EListingVitalsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingVitals"
import { EListingDetailsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingDetails"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { ESellFormLayout } from "@organic-return/foundation-react/src/components/Forms/SellForm"
import { ECardIconsLayout, EContactCardLayout } from "@organic-return/foundation-react/src/components/Contact/ContactCard"
import { ISearchContext } from "@organic-return/foundation-react/src/components/Search/SearchContext"
import { EListingContactContent } from "@organic-return/foundation-react/src/components/Listing/ListingContact"
import { CommunityLayoutHeroGallery } from "@organic-return/foundation-react/src/components/Community/CommunityLayoutHeroGallery"
import { ECloudinaryTransform } from "@organic-return/foundation-react/src/lib/Media/util"

export const SiteContextProvider: React.FC = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteContextProviderQuery {
      config: agilitySiteConfiguration {
        customFields {
          rentalSearchPath,

        }
      }
      allListing {
        distinct(field: minorArea)
      }
      allRental {
        distinct(field: sleeps)
      }
      allRentalLocation: allRental {
        distinct(field: address___city)
      }
      allPerson(sort: {fields: first, order: ASC}) {
        nodes {
          fullName
        }
      }      
    }
  `)
  const selectStyles = {
    control: (base: object) => ({
      ...base,
      borderColor: "transparent",
      background: "transparent",
      borderBottomColor: "var(--color-composite-text)",
      borderRadius: 0,
    }),
    option: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    input: (base: object) => ({
      ...base,
      color: "var(--color-composite-text)",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "var(--color-composite-text)",
      textTransform: "uppercase",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      paddingRight: "0px",
      color: "var(--color-composite-text)",
    }),
    indicatorSeparator: (base: object) => ({
      ...base,
      display: "none",
    }),
    placeholder: (base: object) => ({
      ...base,
      color: "var(--color-composite-text)",
      whiteSpace: "nowrap",
      textTransform: "uppercase",
    }),
    valueContainer: (base: object) => ({
      ...base,
      paddingLeft: 0,
      textTransform: "uppercase",
    }),
    menu: (base: object) => ({
      ...base,
      zIndex: 11,
    }),
  }

  const priceFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          label="Min Price"
          placeholder="Min Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.price || 0) >= parseInt(vstr) || false
          }}
          param="minPrice"
          resolver={arg => arg.price}
          reactSelectStyles={selectStyles}
          options={[
            { label: "$200k", value: "200000" },
            { label: "$400k", value: "400000" },
            { label: "$600k", value: "600000" },
            { label: "$800k", value: "800000" },
            { label: "$1M", value: "1000000" },
            { label: "$3M", value: "3000000" },
            { label: "$5M", value: "5000000" },
            { label: "$7M", value: "7000000" },
            { label: "$10M", value: "10000000" },
            { label: "$15M", value: "15000000" },
          ]}
          context={context}
        />
        <Choose
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.price || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.price}
          reactSelectStyles={selectStyles}
          options={[
            { label: "$200k", value: "200000" },
            { label: "$400k", value: "400000" },
            { label: "$600k", value: "600000" },
            { label: "$800k", value: "800000" },
            { label: "$1M", value: "1000000" },
            { label: "$3M", value: "3000000" },
            { label: "$5M", value: "5000000" },
            { label: "$7M", value: "7000000" },
            { label: "$10M", value: "10000000" },
            { label: "$15M", value: "15000000" },
          ]}
          context={context}
        />
      </>
    )
  }
  const rentalPriceFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          label="Min Price"
          placeholder="Min Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.priceLow || 0) >= parseInt(vstr) || false
          }}
          param="minPrice"
          resolver={arg => arg.priceLow}
          reactSelectStyles={selectStyles}
          options={[
            { label: "$1k", value: "1000" },
            { label: "$2k", value: "2000" },
            { label: "$3k", value: "3000" },
            { label: "$5k", value: "5000" },
            { label: "$7k", value: "7000" },
            { label: "$10k", value: "10000" },
            { label: "$15k", value: "15000" },
            { label: "$20k", value: "20000" },
            { label: "$30k", value: "30000" },
            { label: "$40k", value: "40000" },
          ]}
          context={context}
        />
        <Choose
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.priceLow || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.priceLow}
          reactSelectStyles={selectStyles}
          options={[
            { label: "$1k", value: "1000" },
            { label: "$2k", value: "2000" },
            { label: "$3k", value: "3000" },
            { label: "$5k", value: "5000" },
            { label: "$7k", value: "7000" },
            { label: "$10k", value: "10000" },
            { label: "$15k", value: "15000" },
            { label: "$20k", value: "20000" },
            { label: "$30k", value: "30000" },
            { label: "$40k", value: "40000" },
          ]}
          context={context}
        />
      </>
    )
  }
  const typeFitering = (value: string, argl: IListingSummary) => {
    switch (value) {
      case "Single-Family":
        return argl.subType ? /(Residential|^(?!(Multi|Condo|Land)).*$)/gi.test(argl.subType) : false;
      case "Condominium":
        return argl.subType === "Condominium" || argl.subType === "Condo";
      case "Multi-Family":
        return argl.subType ? /Multi/gi.test(argl.subType) : false;
      case "Land":
        return argl.subType ? /Land/gi.test(argl.subType) : false;
      case "Commercial":
        return argl.subType ? /Commercial/gi.test(argl.subType) : false;
      case "Commercial Lease":
        return argl.type === "Commercial Lease";
      case "Rental":
        return argl.type === "Rental";
      default:
        return false;
    }
  }
  const typeFilters = function (context: ISearchContext, match: "any" | "one") {
    return (
      <Choose
        placeholder="Type"
        label="Type"
        param="type"
        resolver={arg => arg.subType}
        sort="count"
        match={match}
        reactSelectStyles={selectStyles}
        customFilter={(arg, value) => {
          let argl = arg as IListingSummary
          if (!Array.isArray(value)) {
            let vstr = value as string;
            return typeFitering(vstr, argl)
          }
          else {
            let vstr = value as string[]
            return vstr?.some(item => typeFitering(item, argl))
          }
        }}
        options={[
          { label: "Single-Family", value: "Single-Family" },
          { label: "Condominium", value: "Condominium" },
          { label: "Multi-Family", value: "Multi-Family" },
          { label: "Land", value: "Land" },
          { label: "Commercial", value: "Commercial" },
          { label: "Commercial Lease", value: "Commercial Lease" },
          { label: "Rental", value: "Rental" }
        ]}
        context={context}
      />
    )
  }
  const minorAreas = data.allListing?.distinct &&
    data.allListing.distinct.map((minorArea: any) => {
      return {
        value: minorArea,
        label: minorArea
      }
    })

  const rentalsSleeps = data.allRental?.distinct &&
    data.allRental.distinct.sort((a: any, b: any) => (a - b)).map((sleep: any) => {
      return {
        value: sleep,
        label: sleep
      }
    })

  const rentalLocation = data.allRentalLocation?.distinct &&
    data.allRentalLocation.distinct.map((location: any) => {
      return {
        value: location,
        label: location
      }
    })

  const allAgents = data.allPerson &&
    data.allPerson.nodes.map((agent: any) => {
      return agent.fullName
    })

  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          placeholder="Location"
          label="Location"
          param="area2"
          resolver={arg => arg.minorArea}
          match="any"
          reactSelectStyles={selectStyles}
          context={context}
        />
        <Choose
          placeholder="State"
          label="State"
          param="state"
          resolver={arg => arg.address.state}
          match="any"
          reactSelectStyles={selectStyles}
          context={context}
        />
        {typeFilters(context, "any")}
        <Choose
          placeholder="Features"
          label="Features"
          param="features"
          resolver={arg => arg.features}
          sort="count"
          match="all"
          reactSelectStyles={selectStyles}
          context={context}
        />
        {priceFilters(context)}
      </>
    )
  }
  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingEntryFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          placeholder="Location"
          label="Location"
          param="area2"
          resolver={arg => arg.minorArea}
          match="one"
          reactSelectStyles={selectStyles}
          context={context}
          options={minorAreas}
        />
        {typeFilters(context, "one")}
        {priceFilters(context)}
      </>
    )
  }

  const orFilters = function (context: ISearchContext) {
    return (
      <Text
        label="MLS# / Keyword"
        placeholder="MLS# / Keyword"
        className="shrink"
        inputClassName="rounded-none bg-transparent border-b-body-text-inverted text-body-text-inverted placeholder:text-body-text-inverted px-0 min-w-[11ch] max-w-[13ch]"
        param="text"
        context={context}
        resolver={(arg: any, text) => {
          return true
        }}
      />
    )
  }

  /**
   * Standard filters for community page Real Estate listing search.
   */
  const communityStandardFilters = function (context: ISearchContext) {
    return (
      <>
        {typeFilters(context, "any")}
        {priceFilters(context)}
        <Choose
          placeholder="Features"
          label="Features"
          param="features"
          resolver={arg => arg.features}
          sort="count"
          match="all"
          reactSelectStyles={selectStyles}
          context={context}
        />
      </>
    )
  }
  /**
   * Sorts to be used in all Listing (real estate) searches
   */
  const listingSort = function (context: ISearchContext) {
    return (
      <Sort
        className="text-center py-1"
        context={context}
        label="Sort by: "
        sorts={[
          {
            label: "Price: $$$ to $",
            key: "priceDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.price || 0) - (l0.price || 0)
            },
          },
          {
            label: "Price: $ to $$$",
            key: "priceASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.price || 0) - (l1.price || 0)
            },
          },
          {
            label: "Bedrooms: High to Low",
            key: "bedsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.beds || 0) - (l0.beds || 0)
            },
          },
          {
            label: "Bedrooms: Low to High",
            key: "bedsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.beds || 0) - (l1.beds || 0)
            },
          },
          {
            label: "Sq Ft: High to Low",
            key: "sqftDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.sqft || 0) - (l0.sqft || 0)
            },
          },
          {
            label: "Sq Ft: Low to High",
            key: "sqftASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.sqft || 0) - (l1.sqft || 0)
            },
          },
          {
            label: "Lot Size: High to Low",
            key: "lotDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.lotacres || 0) - (l0.lotacres || 0)
            },
          },
          {
            label: "Lot Size: Low to High",
            key: "lotASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.lotacres || 0) - (l1.lotacres || 0)
            },
          },
          {
            label: "Last Updated",
            key: "statusTSDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.statusTS || "").localeCompare(l0.statusTS || "")
            },
          },
        ]}
      />
    )
  }
  /**
   * Middle filter for real estate listing search
   */
  const listingMiddleFilters = function (context: ISearchContext) {
    return (
      <>
        <Text
          placeholder="MLS#, City, Zip, Area"
          param="text"
          context={context}
          label="MLS#, City, Zip, Area"
          inputClassName="bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
          resolver={(arg: any, text) => {
            let argl = arg as IListingSummary
            let match = text.toLowerCase()
            return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
              (argl.address.text &&
                argl.address.text.toLowerCase().includes(match)) ||
              (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
              (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
              (argl.subdivision &&
                argl.subdivision.toLowerCase().includes(match)) ||
              (argl.neighborhood &&
                argl.neighborhood.toLowerCase().includes(match))
              ? true
              : false
          }}
        />
        <Check
          context={context}
          label="Our Properties Only"
          inputClassName=" bg-transparent"
          param="ours"
          className="text-composite-text"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.exclusive || false
          }}
        />
        <Check
          context={context}
          label="Upcoming Open House"
          inputClassName=" bg-transparent"
          param="hasOpenHouse"
          className="text-composite-text"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.hasOpenHouse || false
          }}
        />
      </>
    )
  }
  /**
   * Standard filters for global Rental entry listing search.
   */
  const rentalEntryFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="one"
          reactSelectStyles={selectStyles}
          context={context}
          options={rentalLocation}
        />
        <DateRange
          context={context}
          label="Dates"
          param="dates"
          className=" py-[.4rem] border-b"
          dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
        />
      </>
    )
  }
  /**
   * Advanced filters for global Rental entry listing search.
   */
  const rentalEntryAdvancedFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="one"
          reactSelectStyles={selectStyles}
          context={context}
          options={rentalLocation}
          className="col-span-2"
        />
        {rentalPriceFilters(context)}
        <DateRange
          context={context}
          label="Dates"
          param="dates"
          className=" py-[.4rem] border-b"
          dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
        />
        <div className="col-span-2 row-span-2 grid grid-col-2 gap-3">
          <label className="col-span-2 text-uppercase">ADDITIONAL FILTER</label>
          <Check
            context={context}
            label="Air Conditioning"
            inputClassName=" bg-transparent"
            param="air"
            className="text-composite-text self-end"
            resolver={(arg: any) => {
              let argl = arg as IListingSummary
              return argl.features?.find((str) => str == "A/C") !== undefined
            }}
          />
          <Check
            context={context}
            label="Waterfront"
            inputClassName=" bg-transparent"
            param="waterfront"
            className="text-composite-text self-end"
            resolver={(arg: any) => {
              let argl = arg as IListingSummary
              return argl.features?.find((str) => str == "Waterfront") !== undefined
            }}
          />
          <Check
            context={context}
            label="Linens provided"
            inputClassName=" bg-transparent"
            param="linens"
            className="text-composite-text self-end"
            resolver={(arg: any) => {
              let argl = arg as IListingSummary
              return argl.features?.find((str) => str == "Linens Provided") !== undefined
            }}
          />
          <Check
            context={context}
            label="Pool"
            inputClassName=" bg-transparent"
            param="pool"
            className="text-composite-text self-end"
            resolver={(arg: any) => {
              let argl = arg as IListingSummary
              return argl.features?.find((str) => str == "Pool") !== undefined
            }}
          />
        </div>
      </>
    )
  }
  /**
   * Standard filters for global Rental listing search.
   */
  const rentalStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <div className="grid md:grid-cols-2 md:col-span-2 lg:grid-cols-1 lg:col-span-1 gap-5">
          <Choose
            context={context}
            placeholder="Location"
            label="Location"
            param="address.city"
            resolver={arg => arg.address.city}
            match="any"
            reactSelectStyles={selectStyles}
          />
          <Choose
            context={context}
            placeholder="Time Of Year"
            label="Time Of Year"
            param="season"
            match="one"
            reactSelectStyles={selectStyles}
            options={[
              {
                label: "Summer Rental",
                value: "Summer Rental"
              },
              {
                label: "Winter Rental",
                value: "Winter Rental"
              },
              {
                label: "Year Round",
                value: "Year Round"
              },
            ]}
            customFilter={(arg, value) => {
              return arg.seasonality.includes(value)
            }}
          />
        </div>
        <div className="grid md:grid-cols-2 md:col-span-2 lg:grid-cols-1 lg:col-span-1">
          <Choose
            context={context}
            placeholder="Sleeps"
            label="Sleeps"
            param="sleeps"
            options={rentalsSleeps}
            match="one"
            reactSelectStyles={selectStyles}
            customFilter={(arg, value) => {
              let parsedValue = parseInt(value) || -1
              return arg.sleeps >= parsedValue
            }}
          />
        </div>
        <div className="grid md:grid-cols-2 md:col-span-2 lg:grid-cols-1 lg:col-span-1 gap-5">
          <DateRange
            context={context}
            label="Dates"
            param="dates"
            className=" py-[.4rem] border-b"
            dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
          />
        </div>
      </>
    )
  }
  /**
   * Middle filter for real estate listing search
   */
  const rentalMiddleFilters = function (context: ISearchContext) {
    return (
      <Text
        context={context}
        placeholder="Keywords, City, Address"
        param="text"
        label="Keywords, City, Address"
        inputClassName="w-full bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
        resolver={(arg: any, text) => {
          let argl = arg as IListingSummary
          let match = text.toLowerCase()
          return (argl.address.text &&
            argl.address.text.toLowerCase().includes(match)) ||
            (argl.subdivision &&
              argl.subdivision.toLowerCase().includes(match)) ||
            (argl.neighborhood &&
              argl.neighborhood.toLowerCase().includes(match))
            ? true
            : false
        }}
      />
    )
  }
  /**
   * Sorts to be used in all Rental searches
   */
  const rentalSort = function (context: ISearchContext) {
    return (
      <Sort
        context={context}
        className="text-center py-1"
        label="Sort by: "
        sorts={[
          {
            label: "Price: $$$ to $",
            key: "priceDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              let l0Divider = 1;
              let l1Divider = 1;
              if (l0?.priceSuffix?.includes("MO")) {
                l0Divider = 4.3;
              }
              if (l1?.priceSuffix?.includes("MO")) {
                l1Divider = 4.3;
              }

              return (
                (l1.price || l1.priceHigh || 0) / l1Divider - (l0.price || l0.priceHigh || 0) / l0Divider
              )
            },
          },
          {
            label: "Price: $ to $$$",
            key: "priceASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              let l0Divider = 1;
              let l1Divider = 1;
              if (l0?.priceSuffix?.includes("MO")) {
                l0Divider = 4.3;
              }
              if (l1?.priceSuffix?.includes("MO")) {
                l1Divider = 4.3;
              }
              return (
                (l0.price || l0.priceLow || 0) / l0Divider - (l1.price || l1.priceLow || 0) / l1Divider
              )
            },
          },
          {
            label: "Bedrooms: High to Low",
            key: "bedsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.beds || 0) - (l0.beds || 0)
            },
          },
          {
            label: "Bedrooms: Low to High",
            key: "bedsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.beds || 0) - (l1.beds || 0)
            },
          },
          {
            label: "Sleeps: High to Low",
            key: "sleepsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.sleeps || 0) - (l0.sleeps || 0)
            },
          },
          {
            label: "Sleeps: Low to High",
            key: "sleepsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.sleeps || 0) - (l1.sleeps || 0)
            },
          },
        ]}
      />
    )
  }

  const peoplePrimaryFilters = function (context: ISearchContext) {
    return (
      <>
        <Text
          context={context}
          placeholder="Keywords, Name, Email, etc."
          label="Keywords, Name, Email, etc."
          param="text"
          inputClassName="bg-transparent text-composite-text border-composite-text"
          resolver={(arg: any, text) => {
            let argl = arg as IAgent
            let match = text.toLowerCase()
            return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
              (argl.fullName && argl.fullName.toLowerCase().includes(match)) ||
              (argl.first && argl.first.toLowerCase().includes(match)) ||
              (argl.last && argl.last.toLowerCase().includes(match)) ||
              (argl.email && argl.email.toLowerCase().includes(match)) ||
              (argl.workTitle && argl.workTitle.toLowerCase().includes(match))
              ? true
              : false
          }}
        />
      </>
    )
  }

  const arrowsBaseClasses = "absolute top-1/2 z-10 cursor-pointer text-white text-6xl -mt-6 drop-shadow bg-transparent";

  const PrevArrow = (props: any) => {
    return (
      <button
        {...props}
        className={`left-0 ${arrowsBaseClasses}`}
      >
        <svg width="72" height="48" viewBox="0 0 72 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="24" cy="24" r="22" stroke="white" stroke-width="4" /><path d="M28.1999 10.2L14.3999 24L28.1999 37.8" stroke="white" stroke-width="4" /><path d="M14.9999 23.9999L71.0999 23.9999" stroke="white" stroke-width="4" /></svg>
      </button>
    );
  }

  const NextArrow = (props: any) => {
    return (
      <button
        {...props}
        className={`right-0 ${arrowsBaseClasses}`}
      >
        <svg width="72" height="48" viewBox="0 0 72 48" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="47.1002" cy="24" r="22" transform="rotate(-180 47.1002 24)" stroke="white" stroke-width="4" /><path d="M42.9003 37.8L56.7003 24L42.9003 10.2" stroke="white" stroke-width="4" /><path d="M56.1003 24.0001L0.000284106 24.0001" stroke="white" stroke-width="4" /></svg>
      </button>
    );
  }

  const newDate = new Date();

  const disclaimerList = [
    {
      mlsSourceName: 'MREIS',
      disclaimerText: `<div class="w-full" style="padding-bottom: 15px;">
      <img alt="MREIS logo" id="mreislogo" src="https://s3.amazonaws.com/files.usmre.com/mreis-logo.jpg" style="width: 100px; height: 42px;">
    </div>
    <div class="w-full" style="padding: 5px 0;">
      Listing data is derived in whole or in part from the Maine IDX &amp; is for consumers' personal, non commercial use only. Dimensions are approximate and not guaranteed. All data should be independently verified. ©${new Date().getFullYear()} Maine Real Estate Information System, Inc. All Rights Reserved. <img class="inline-block" alt="equal opportunity housing logo" src="https://s3.amazonaws.com/files.usmre.com/equal_housing.png" style="width: 24px; height: 20px;"><span style="padding-left: 15px;"><a href="/privacy-policy" target="_blank">Privacy Policy</a></span>
    </div>
    <div class="w-full" style="padding: 5px 0;">
      Anne Erwin Sotheby's International Realty participates in MREIS' Maine Internet Data Exchange (Maine IDX) program, allowing us to display other Maine IDX Participants' listings. This website does not display complete listings. Certain listings of other real estate brokerage firms have been excluded.
    </div>`
    },
    {
      mlsSourceName: 'PrimeMLS',
      disclaimerText: `<div class="w-full" style="padding-bottom: 15px;">
      <img alt="PrimeMLS logo" id="primemlslogo" src="https://cdn.aglty.io/anne-erwin-sir/Attachments/primemls-logo.png" style="height: 35px;">
    </div>
    <div class="w-full" style="padding: 5px 0;">
      Copyright ${newDate.getFullYear()} PrimeMLS, Inc. All rights reserved. This information is deemed reliable, but not guaranteed. The data relating to real estate displayed on this Site comes in part from the IDX Program of PrimeMLS. The information being provided is for consumers’ personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Data last updated ${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()}
    </div>`
    }

  ];

  /**
   * Wrap our pages in the SiteContext to provide configuration and
   * customization of various site elements.  See all usages of useSiteContext()
   * in @organic-return/foundation-gatsby-plugin-agility
   */
  return (
    <SiteContext.Provider
      value={{
        localGetModule: getModule,
        localGetPageTemplate: getPageTemplate,
        listingLayoutProps: {
          vitalsProps: {
            className: "mb-3 inverted uppercase",
            layout: EListingVitalsLayout.column1Border1Alt2,
            locationIcon: false,
            twoLineAddress: true,
            omitAddressParts: ["state", "postal"],
            showCourtesy: true,
          },
          formProps: {
            title: "REQUEST INFORMATION",
            classNames: "strictTwoColumn inverted property-contact",
            inputTextAreaLabel: "Add a message here...",
          },
          similarCardProps: {
            linkText: "View Listing",
            vitalsLayout: EListingVitalsLayout.row1Border0,
          },
          summaryProps: {
            className: "non-exlusive max-w-screen-xl mx-auto p-4",
            showFeatures: false,
            showListingAgentPhone: true,
          },
          mapProps: { title: "", className: "w-screen mb-10" },
          detailsProps: {
            title: "DETAILS",
            layout: EListingDetailsLayout.groupNone,
            className:
              "bg-zinc-200 flex flex-col items-center px-10 pt-5 pb-14 mt-10",
          },
          similarCarouselProps: { title: "Similar Properties" },
          rowClassName: "max-w-screen-xl mx-auto",
          showContactUsForm: true,
          contactUsFormProps: {
            checkboxGroups: {
              label: "I am interested in the following",
              nestedGroups: [
                {
                  list: [
                    {
                      value: "Having a broker contact me",
                      sublist: ["Phone call", "Text", "Email"]
                    },
                  ],
                },
                {
                  list: [
                    {
                      value: "Renting a property",
                      sublist: ["Summer", "Winter", "Year Round"],
                    },
                    {
                      value: "Selling or buying a property",
                    }
                  ]
                },
                {
                  list: [
                    {
                      value: "Kittery"
                    },
                    {
                      value: "York"
                    },
                    {
                      value: "Eliot"
                    },
                    {
                      value: "The Kennebunks"
                    },
                    {
                      value: "Ogunquit"
                    },
                    {
                      value: "Wells"
                    },
                    {
                      value: "South Berwick"
                    },
                    {
                      value: "Seacoast NH"
                    },
                  ]
                }
              ],
            },
            inputTextAreaLabel: "Tell us about your wishlist",
            inputTextAreaRequired: true,
            inputSelect: {
              label: "I'M WORKING WITH",
              list: allAgents
            }
          },
          virtualTourTabTitle: "3D Tour",
          displayDisclaimer: true,
          disclaimers: disclaimerList,
          disclaimerCssClasses: "max-w-screen-xl mx-auto px-5 mb-10"
        },
        exclusiveLayoutProps: {
          vitalsProps: {
            composite: true,
            className: "mb-3 text-center uppercase",
            layout: EListingVitalsLayout.column2Border2,
            locationIcon: false,
          },
          summaryProps: {
            showFeatures: false,
            showListingAgentPhone: true,
          },
          formProps: {
            title: "Request Information",
            classNames: "strictTwoColumn inverted property-contact",
            inputTextAreaLabel: "Add a message here...",
          },
          contactProps: {
            contentVariant: EListingContactContent.office
          },
          similarCardProps: {
            linkText: "View Listing",
            vitalsLayout: EListingVitalsLayout.row1Border0,
          },
          mapProps: { title: "", className: "w-screen mb-10" },
          detailsProps: {
            title: "DETAILS",
            layout: EListingDetailsLayout.groupNone,
            className: "max-w-screen-xl mx-auto px-10 pb-15 my-10",
          },
          carouselProps: {
            slickSettings: {
              nextArrow: <NextArrow />,
              prevArrow: <PrevArrow />
            },
          },
          similarCarouselProps: { title: "Similar Properties" },
          rowClassName: "max-w-screen-xl mx-auto",
          showContactUsForm: true,
          contactUsFormProps: {
            title: "INQUIRE",
            checkboxGroups: {
              label: "I am interested in the following",
              nestedGroups: [
                {
                  list: [
                    {
                      value: "Having a broker contact me",
                      sublist: ["Phone call", "Text", "Email"]
                    },
                  ],
                },
                {
                  list: [
                    {
                      value: "Renting a property",
                      sublist: ["Summer", "Winter", "Year Round"],
                    },
                    {
                      value: "Selling or buying a property",
                    }
                  ]
                },
                {
                  list: [
                    {
                      value: "Kittery"
                    },
                    {
                      value: "York"
                    },
                    {
                      value: "Eliot"
                    },
                    {
                      value: "The Kennebunks"
                    },
                    {
                      value: "Ogunquit"
                    },
                    {
                      value: "Wells"
                    },
                    {
                      value: "South Berwick"
                    },
                    {
                      value: "Seacoast NH"
                    },
                  ]
                }
              ],
            },
            inputTextAreaLabel: "Tell us about your wishlist",
            inputTextAreaRequired: true,
            inputSelect: {
              label: "I'M WORKING WITH",
              list: allAgents
            }
          },
          virtualTourTabTitle: "3D Tour",
          displayDisclaimer: true,
          disclaimers: disclaimerList,
          disclaimerCssClasses: "max-w-screen-xl mx-auto px-5 mb-10",
          showFlags: true,
        },
        rentalLayoutProps: {
          className: "useAltLayout",
          vitalsProps: {
            className: "mb-3 inverted",
          },
          detailsProps: {
            title: "Full Details",
            className: "max-w-screen-xl mx-auto",
            layout: EListingDetailsLayout.groupColumns,
          },
          similarCarouselProps: {
            title: "Similar Rentals",
          },
          calendarProps: {
            monthsShown: 4,
            displayLabels: true,
            timezone: "America/New_York",
          },
          summaryProps: {
            className: "max-w-screen-xl mx-auto p-4",
            showFeatures: false,
            showListingAgentPhone: true,
          },
          rowClassName: "max-w-screen-xl mx-auto",
          mapProps: { title: "", className: "mt-10" },
          formProps: {
            classNames: "strictTwoColumn inverted textarea-body-text-color",
            title: "Request Information",
            inputTextAreaLabel: "Add a message here...",
          },
          showContactUsForm: true,
          contactUsFormProps: {
            title: "INQUIRE-RENTALS",
            checkboxGroups: {
              label: "I am interested in the following",
              groups: [
                { list: ["Summer/Vacation", "Winter", "Year-Round"] },
                { list: ["Kittery", "York", "Eliot", "The Kennebunks", "Ogunquit", "Wells", "South Berwick", "Seacoast NH"] },
                { list: ["Pets considered"] },
              ]
            },
            inputTextAreaLabel: "Tell us about your wishlist",
            inputTextAreaRequired: true,
          },
        },
        listingSearchProps: {
          primaryFilters: listingStandardFilters,
          alternateFilters: listingMiddleFilters,
          sort: listingSort,
          filterClassName: "inverted",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-screen",
          resultsClassName: "max-w-screen-2xl mx-auto",
        },
        listingEntrySearchProps: {
          standardFilters: listingEntryFilters,
          orFilters: orFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
        },
        communitySearchProps: {
          primaryFilters: communityStandardFilters,
          alternateFilters: listingMiddleFilters,
          filterClassName: "inverted",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          resultsClassName: "max-w-screen-2xl mx-auto",
          mapClassName: "w-screen",
          sort: listingSort,
        },
        rentalSearchProps: {
          sort: rentalSort,
          primaryFilters: rentalStandardFilters,
          alternateFilters: rentalMiddleFilters,
          filterClassName: "inverted text-white",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-screen",
          resultsClassName: "max-w-screen-2xl mx-auto",
          defaultSort: "priceDESC"
        },
        rentalEntrySearchProps: {
          standardFilters: rentalEntryFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
        },
        rentalEntryAdvancedSearchProps: {
          standardFilters: rentalEntryAdvancedFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
          onSubmit: async (filters: any) => {
            let featuresFilter: string[] = []
            filters["air"] && featuresFilter.push("A/C")
            filters["waterfront"] && featuresFilter.push("Waterfront")
            filters["linens"] && featuresFilter.push("Linens Provided")
            filters["pool"] && featuresFilter.push("Pool")

            if (featuresFilter.length > 0) {
              filters = {
                ...filters,
                features: featuresFilter,
              }
            }

            const params = new URLSearchParams({
              filters: JSON.stringify(filters),
            })
            await navigate(
              data.config.customFields.rentalSearchPath +
              "#" +
              params.toString()
            )
          },
        },
        globalContactFormProps: {
          checkboxGroups: {
            label: "I am interested in the following",
            nestedGroups: [
              {
                list: [
                  {
                    value: "Having a broker contact me",
                    sublist: ["Phone call", "Text", "Email"]
                  },
                ],
              },
              {
                list: [
                  {
                    value: "Renting a property",
                    sublist: ["Summer", "Winter", "Year Round"],
                  },
                  {
                    value: "Selling or buying a property",
                  }
                ]
              },
              {
                list: [
                  {
                    value: "Kittery"
                  },
                  {
                    value: "York"
                  },
                  {
                    value: "Eliot"
                  },
                  {
                    value: "The Kennebunks"
                  },
                  {
                    value: "Ogunquit"
                  },
                  {
                    value: "Wells"
                  },
                  {
                    value: "South Berwick"
                  },
                  {
                    value: "Seacoast NH"
                  },
                ]
              }
            ],
          },
          inputTextAreaLabel: "Tell us about your wishlist",
          inputTextAreaRequired: true,
          inputSelect: {
            label: "I'M WORKING WITH",
            list: allAgents
          }
        },
        globalSellFormProps: {
          layout: ESellFormLayout.row2map,
          propertyTypes: [
            {
              type: "Residential",
              subtypes: [
                "Single Family Residence",
                "Stock Cooperative",
                "Condominium",
                "Apartment",
                "Townhouse",
                "Mobile Home",
                "Mixed Use",
                "Office",
              ],
            },
            {
              type: "Rental",
              subtypes: [
                "Apartment",
                "Single Family Residence",
                "Condominium",
                "Multi Family",
                "Stock Cooperative",
                "Duplex",
                "Townhouse",
                "Mixed Use",
                "Residential",
                "Mobile Home",
                "Triplex",
                "House (detached)",
                "Office",
                "Condotel",
              ],
            },
            {
              type: "Detached Home",
              subtypes: [],
            },
            {
              type: "Multi-family",
              subtypes: ["Multi Family", "Duplex", "Triplex", "Townhouse"],
            },
            {
              type: "Commercial",
              subtypes: [
                "Mixed Use",
                "Office",
                "Retail",
                "Industrial",
                "Warehouse",
                "Apartment",
                "Townhouse",
              ],
            },
            {
              type: "Lots & Land",
              subtypes: ["Unimproved Land"],
            },
            {
              type: "Condo",
              subtypes: [],
            },
            {
              type: "Business Opportunity",
              subtypes: [
                "Retail",
                "Mixed Use",
                "Industrial",
                "Office",
                "Apartment",
                "Warehouse",
              ],
            },
            {
              type: "Co-op",
              subtypes: [],
            },
            {
              type: "Multi-family Townhouse",
              subtypes: [],
            },
            {
              type: "Single-family Townhouse",
              subtypes: [],
            },
            {
              type: "Land",
              subtypes: [],
            },
            {
              type: "House (detached)",
              subtypes: [],
            },
            {
              type: "Vacant Land",
              subtypes: [],
            },
            {
              type: "Condo",
              subtypes: [],
            },
            {
              type: "Commercial Building",
              subtypes: [],
            },
            {
              type: "House (attached)",
              subtypes: [],
            },
            {
              type: "House W/accessory",
              subtypes: [],
            },
          ],
          propertyTypeSelectLabel: "Property Type",
          propertySubtypeSelectLabel: "Sub-Type",
          bedsSelect: {
            placeholder: "Beds",
            selectOptions: [
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7+",
                value: "7+",
              },
            ],
          },
          bathsSelect: {
            placeholder: "Baths",
            selectOptions: [
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7+",
                value: "7+",
              },
            ],
          },
          squareFootageSelect: {
            placeholder: "Sq. Ft.",
            selectOptions: [
              {
                label: "Under 1000 ft²",
                value: "Under 1000 ft²",
              },
              {
                label: "1000-2000 ft²",
                value: "1000-2000 ft²",
              },
              {
                label: "2000-3000 ft²",
                value: "2000-3000 ft²",
              },
              {
                label: "3000-4000 ft²",
                value: "3000-4000 ft²",
              },
              {
                label: "4000-5000 ft²",
                value: "4000-5000 ft²",
              },
              {
                label: "5000-6000 ft²",
                value: "5000-6000 ft²",
              },
              {
                label: "6000-7000 ft²",
                value: "6000-7000 ft²",
              },
              {
                label: "7000-8000 ft²",
                value: "7000-8000 ft²",
              },
              {
                label: "Over 8000 ft²",
                value: "Over 8000 ft²",
              },
            ],
          },
          conditionSelect: {
            placeholder: "Condition",
            selectOptions: [
              {
                label: "Excelent",
                value: "Excelent",
              },
              {
                label: "Good",
                value: "Good",
              },
              {
                label: "Average",
                value: "Average",
              },
              {
                label: "Fair",
                value: "Fair",
              },
              {
                label: "Poor",
                value: "Poor",
              },
            ],
          },
          inputTextAreaLabel: "Additional information...",
        },
        agentLayoutProps: {
          showRequestInfoForm: false,
          carouselProps: {
            slickSettings: {
              prevArrow: <PrevArrow />,
              nextArrow: <NextArrow />
            }
          },
          carouselListingCardVitalsLayout: EListingVitalsLayout.column2Border2,
          contactCardProps: {
            layout: EContactCardLayout.twoColsAndForm,
            contactInfoItemsLayout: ECardIconsLayout.columns,
            showVCard: false,
            showAddress: false,
            iconAccent: false,
            showOffices: true,
            showBio: true,
            formTitle: "REQUEST INFORMATION",
            className: "max-w-screen-xl mx-auto textarea-body-text-color",
            imageCloudinaryTransform: ECloudinaryTransform.thumb9x10
          },
          testimonialsProps: {
            title: "TESTIMONIALS",
            titleHeadingLevel: "h1",
            headingClass: "pb-5 text-2xl font-bold max-w-screen-xl mx-auto md:text-left",
            className: "inverted",
            columnsWrapperClass: "w-full max-w-screen-xl mx-auto grid grid-cols-6 lg:gap-16",
            leftColumnClass: "hidden lg:block lg:col-span-1",
            rightColumnClass: "col-span-6 lg:col-span-5",
            dividerClass: "hidden",
            leftColumn: (
              <div className="w-full h-px bg-white"></div>
            ),
            showQuotes: false,
            showAttribution: true,
          },
          listingsTabsProps: {
            activeListingsTabTitle: "Active",
            activeTabClass: "border-b-4 font-bold text-accent border-accent uppercase tracking-[0.25em]",
            defaultTabClass: "text-body-text uppercase tracking-[0.25em]",
          },
          showContactUsForm: true,
          contactUsFormProps: {
            title: "INQUIRE",
            checkboxGroups: {
              label: "I am interested in the following",
              nestedGroups: [
                {
                  list: [
                    {
                      value: "Having a broker contact me",
                      sublist: ["Phone call", "Text", "Email"]
                    },
                  ],
                },
                {
                  list: [
                    {
                      value: "Renting a property",
                      sublist: ["Summer", "Winter", "Year Round"],
                    },
                    {
                      value: "Selling or buying a property",
                    }
                  ]
                },
                {
                  list: [
                    {
                      value: "Kittery"
                    },
                    {
                      value: "York"
                    },
                    {
                      value: "Eliot"
                    },
                    {
                      value: "The Kennebunks"
                    },
                    {
                      value: "Ogunquit"
                    },
                    {
                      value: "Wells"
                    },
                    {
                      value: "South Berwick"
                    },
                    {
                      value: "Seacoast NH"
                    },
                  ]
                }
              ],
            },
            inputTextAreaLabel: "Tell us about your wishlist",
            inputTextAreaRequired: true,
            inputSelect: {
              label: "I'M WORKING WITH",
              list: allAgents
            }
          }
        },
        globalListingCarouselProps: {
          slickSettings: {
            prevArrow: <PrevArrow />,
            nextArrow: <NextArrow />,
          }
        },
        globalListingCarouselCardProps: {
          vitalsLayout: EListingVitalsLayout.column2Border2,
          imageProps: {
            lazy: false,
            imgProps: {
              className:
                "w-full h-auto xl:h-screen xl:max-h-screen xl:object-cover xl:object-center"
            }
          }
        },
        communityPageLayout: CommunityLayoutHeroGallery,
        communityLayoutProps: {
          listingsResultPageSize: 4,
          heroCarouselProps: {
            slickSettings: {
              prevArrow: <PrevArrow />,
              nextArrow: <NextArrow />,
            }
          },
          contactUsFormProps: {
            title: "INQUIRE",
            checkboxGroups: {
              label: "I am interested in the following",
              groups: [
                { list: ["Having a broker contact me"] },
                { list: ["Renting a property", "Selling or buying a property"] },
                {
                  list: [
                    "Kittery",
                    "York",
                    "Eliot",
                    "The Kennebunks",
                    "Ogunquit",
                    "Wells",
                    "South Berwick",
                    "Seacoast NH",
                  ],
                },
              ],
            },
            inputTextAreaLabel: "Tell us about your wishlist",
            inputTextAreaRequired: true,
          },
          requestInfoFormProps: {
            checkboxGroups: {
              groups: [
                { list: ["Sale", "Rentals"] },
              ],
            }
          },
          mapBladeProps: {
            mapClick: (item) => { navigate(item.path) },
            markerProps: { icon: "/marker.svg" },
          }
        },
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}
